import React, { useEffect, useState, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import tw, { styled } from "twin.macro";
import classNames from "classnames";
import Layout from "../components/Layout/Layout";
import SEO from "../components/seo";
import PageHero from "../components/PageSections/PageHero";
import GetInvolved from "../components/PageSections/GetInvolved";

const GalleryContainer = styled.main`
  background-color: #ffffff;
  color: var(--color--offBlack);
  transition: background-color 0.5s ease, color 0.5s ease;

  .intro-body p {
    ${tw`leading-relaxed`}
  }
`;

const Gallery = styled.section`
  ${tw`py-32`}
`;

const GalleryFigure = styled.figure`
  ${tw`mx-auto pb-24 max-w-md md:pb-32 lg:pb-48 last:pb-0 md:max-w-xl lg:max-w-4xl xl:max-w-5xl`};
  position: relative;
`;

const GalleryImage = styled.img`
  ${tw`pb-5`};
  object-fit: contain;
  max-height: 20rem;
  max-width: 100%;
  width: 100%;

  @media (min-width: 768px) {
    padding-bottom: 2rem;
    max-height: 25rem;
  }

  @media (min-width: 1024px) {
    max-height: 40rem;
    max-width: 50rem;
    margin: 0 auto;
  }
`;

const GalleryQuote = styled.h5`
  color: var(--color-darkBlue);
  ${tw`mx-auto leading-tight pb-5 text-center`};
  ${tw`md:pb-8`}
`;

const GallerySource = styled.figcaption`
  ${tw`text-center`}
  color: var(--color-grey);
  font-family: attribute-mono, monospace;
  font-weight: 300;
  margin: 0;
  text-transform: uppercase;

  font-size: 12px;
  line-height: 17px;

  @media (min-width: 768px) {
    font-size: 17px;
    line-height: 24px;
    letter-spacing: -0.2px;
  }
`;

const GalleryPage = () => {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "gallery" } }) {
        nodes {
          title
          acf {
            intro {
              header
              body
            }
            hero {
              art {
                source_url
              }
              artist {
                credit
                age
              }
              title
              copy
              cta_type
              cta_link
              cta_text
            }
            content_page {
              ... on WordPressAcf_image_block {
                id
                age
                credit
                image {
                  source_url
                }
              }
              ... on WordPressAcf_text_block {
                id
                copy
                credit
              }
            }
          }
          yoast_meta {
            yoast_wpseo_social_defaults {
              og_frontpage_image {
                source_url
              }
            }
            yoast_wpseo_metadesc
            yoast_wpseo_facebook_title
            yoast_wpseo_facebook_image {
              source_url
            }
            yoast_wpseo_facebook_description
            yoast_wpseo_twitter_title
            yoast_wpseo_twitter_image {
              source_url
            }
            yoast_wpseo_twitter_description
          }
        }
      }
    }
  `);

  const galleryData = data.allWordpressPage.nodes[0];

  const { yoast_meta, title } = data.allWordpressPage.nodes[0];

  const { hero, content_page, intro } = galleryData?.acf;

  const {
    yoast_wpseo_metadesc: seo_desc,
    yoast_wpseo_facebook_title: seo_fb_title,
    yoast_wpseo_facebook_description: seo_fb_desc,
    yoast_wpseo_twitter_title: seo_tw_title,
    yoast_wpseo_twitter_description: seo_tw_desc
  } = yoast_meta;

  const seo_image =
    yoast_meta.yoast_wpseo_social_defaults?.og_frontpage_image?.source_url ||
    "";
  const seo_fb_image =
    yoast_meta.yoast_wpseo_facebook_image?.source_url || seo_image;
  const seo_tw_image =
    yoast_meta.yoast_wpseo_twitter_image?.source_url || seo_image;

  return (
    <Layout>
      <SEO
        title={title}
        description={seo_desc}
        image={seo_fb_image}
        metaTitleFacebook={seo_fb_title}
        metaImageFacebook={seo_fb_image}
        metaDescFacebook={seo_fb_desc}
        metaTitleTwitter={seo_tw_title}
        metaImageTwitter={seo_tw_image}
        metaDescTwitter={seo_tw_desc}
      />

      <PageHero {...hero} page="gallery" />
      <GalleryContainer>
        <div
          className="py-12 md:py-24 text-left"
          style={{ borderBottom: "1px solid rgba(124, 135, 149, 0.3)" }}
        >
          <div className="container md:max-w-xl lg:max-w-4xl xl:max-w-5xl">
            <h4>{intro.header}</h4>
            <div
              className="intro-body"
              dangerouslySetInnerHTML={{ __html: intro.body }}
            ></div>
          </div>
        </div>
        <div class="container">
          <Gallery>
            {content_page.map(item => {
              return (
                <GalleryFigure key={item.id}>
                  {item.__typename === "WordPressAcf_text_block" ? (
                    <React.Fragment>
                      <GalleryQuote>{item?.copy}</GalleryQuote>
                      <GallerySource>{item?.credit}</GallerySource>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <GalleryImage
                        src={item?.image?.source_url}
                        alt={`${item?.credit}:${item?.age}`}
                      />
                      <GallerySource>
                        {item?.credit} 
                        {item?.age && (
                          <React.Fragment>— Age {item.age}</React.Fragment>
                        )}
                      </GallerySource>
                    </React.Fragment>
                  )}
                </GalleryFigure>
              );
            })}
          </Gallery>
        </div>
      </GalleryContainer>
      <GetInvolved />
    </Layout>
  );
};

export default GalleryPage;
